import React, { useState } from "react";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "@mui/material/Button";
import CheckoutForm from "./payments/PaymentForm";
import FormHelperText from "@mui/material/FormHelperText";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lightBlue } from "@mui/material/colors";
import { ImHammer2 } from "react-icons/im";
import axios from "axios";
import "./payments/payments.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: lightBlue,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem", // Increase the legend size here
          color: "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%)",
          fontWeight: "bolder",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "75px",
        },
      },
    },
  },
});

const LegalFeesSelection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const classes = "";
  const [selectedPlan, setSelectedPlan] = useState(0)
  const [advocateCode, setAdvocateCode] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({
    advocateCode: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      advocateCode: "",
    };


    setErrors(newErrors);
    return valid;
  };

  const sebdFormDataTwo = async (event) => {
    event?.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://www.consumercomplaints.info/legalmotocall.php",
          {
            plan: selectedPlan,
            advocateCode: advocateCode,
          },
          {
            headers: {
              "x-api-secret": "XrKylwnTF3GpBbmgiCbVxYcCMkNvv8NHYdh9v5am",
            },
          }
        );
        console.log(response);
        if (response?.data?.status == "true") {
          sessionStorage?.removeItem("plan");
          navigate("/checkout");
        } else {
          console.log("Network response was not ok.");
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (validateForm()) {
      // Handle form submission logic here
      try {
        const response = await axios.post(
          "https://admin.thelegalmoto.com/api/client/register",
          {
            plan: selectedPlan,
            advocateCode: advocateCode,
          },
          {
            headers: {
              "x-api-secret": "XrKylwnTF3GpBbmgiCbVxYcCMkNvv8NHYdh9v5am",
            },
          }
        );
        console.log(response.data);
        localStorage.setItem("twToken", response?.data?.token);
        sessionStorage.setItem("twToken", response?.data?.token);

        sessionStorage.setItem(
          "complaintTitle",
          response?.data?.data?.client?.firstname
        );
        sessionStorage.setItem("email", response?.data?.data?.client?.email);
        sessionStorage.setItem("phone", response?.data?.data?.client?.phone);
        sessionStorage.setItem(
          "country",
          response?.data?.data?.client?.country
        );
        sessionStorage.setItem(
          "complaint",
          response?.data?.data?.complaint?.description
        );
        sebdFormDataTwo();

        // setIsSubmitted(true)
        setShowLoader(false);
      } catch (err) {
        console.log("error", err);
        const errorObj = err?.response?.data?.errors;
        const erObj = errors;
        for (let errres in errorObj) {
          if (errres === "firstname") {
            erObj.complaintTitle = errorObj[errres];
          }
          if (errres === "email") {
            erObj.email = errorObj[errres];
          }
          if (errres === "phone") {
            erObj.phone = errorObj[errres];
          }
          if (errres === "country") {
            erObj.country = errorObj[errres];
          }
          if (errres === "complaint") {
            erObj.complaint = errorObj[errres];
          }
        }
        setErrors(erObj);

        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && (
        <div className="preloader">
          <div className="cssload-dots">
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
          </div>
        </div>
      )}
      {isSubmitted ? (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.1)] !text-white !h-[100%]  ">
            <CheckoutForm />
          </div>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.2)] !text-white !h-[100%] ">
            <h3
              className="text-center text-[18px] h-[7.5vh] mb-0 flex items-center justify-center complaintAgainestFormTitle"
              // style={{
              //   background: "linear-gradient(45deg, red, transparent)",
              // }}
            >
              Below Fees Include Advocate Fees, Document Charge, Stamp Duty,
              Notary Charge, RSP Charge etc.,
            </h3>
            <form
              className="bg-[rgba(0,0,0,0.2)] !text-white"
              onSubmit={handleSubmit}
            >
              <div className="row flex justify-center">
                <div className="col-md-10">
                  <div className="inputGroup !my-4">
                    <input
                      id="radio1"
                      className="amount"
                      name="amount"
                      type="radio"
                      value="1768.82"
                    />
                    <label for="radio1">
                      LEGAL FEES Rs.1499.00 + 18% GST
                      <br />
                      <span>Lost Amount Rs. 0 – 25,000/- </span>
                    </label>
                  </div>
                  <div className="inputGroup !my-4">
                    <input
                      id="radio2"
                      className="amount"
                      name="amount"
                      type="radio"
                      value="2358.82"
                      data-gtm-form-interact-field-id="0"
                    />
                    <label for="radio2">
                      LEGAL FEES Rs.1999.00 + 18% GST
                      <br />
                      <span>Lost Amount Rs. 25001 – 1,00,000/-</span>{" "}
                    </label>
                  </div>
                  <div className="inputGroup !my-4">
                    <input
                      id="radio3"
                      className="amount"
                      name="amount"
                      type="radio"
                      value="3538.82"
                    />
                    <label for="radio3">
                      LEGAL FEES Rs.2999.00 + 18% GST
                      <br />
                      <span> Lost Amount Rs.1,00,000/- &amp; Above </span>{" "}
                    </label>
                  </div>
                  <div className="inputGroup">
                    <input
                      id="radio4"
                      className="amount"
                      name="amount"
                      type="radio"
                      value="5898.82"
                    />
                    <label for="radio4">
                      LEGAL FEES Rs.4999.00 + 18% GST
                      <br />
                      <span> Document Preparation and Case Filing </span>{" "}
                    </label>
                  </div>
                  <div className=" col-md-12">
                    <TextField
                      // style={{
                      //   backgroundColor: "rgba(255,255,255,1)",
                      //   color: "#333",
                      //   // border: "1px solid black",
                      //   //borderRadius:"10px"
                      //   }}
                      className="bg-[rgba(255,255,255,1)] text-[#333]"
                      //label="Phone Number"
                      placeholder="Advocate code (Optional)"
                      variant="outlined"
                      value={advocateCode}
                      onChange={(event) => setAdvocateCode(event.target.value)}
                      fullWidth
                      margin="normal"
                      error={!!errors.advocateCode}
                    />
                    {errors.advocateCode && (
                      <div
                        className=" !pl-3  pt-1 pb-1 !rounded-none bg-[rgba(250,0,0,0.4)]"
                        // style={{ background: "rgba(250,0,0,0.4)" }}
                        role=""
                      >
                        <FormHelperText className="text-white font-bold" error>
                          {errors.advocateCode}
                        </FormHelperText>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 flex items-center justify-center pb-10 mt-3">
                  <Button
                    className="btn btn-white btn-animated "
                    type="submit"
                    variant="contained"
                    size="4vw"
                    style={{
                      margin: theme.spacing(1),
                      fontWeight: "bold",
                      background:
                        "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%) !important",
                      color: "white",
                      width: "60%",
                      height: "6vh",
                      fontSize: "larger",
                    }}
                  >
                    <ImHammer2 className="mr-2" />
                    Submit Complaint
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default LegalFeesSelection;
